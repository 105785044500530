.privacy-policy-content {
    background-color: #f0f2f5;
    padding: 20px 50px;
}

.pageImgOuter {
    position: relative;
    height: 300px;
    overflow: hidden;
}

.pageImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.pageTitle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 36px;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
}

.contentWrapper {
    background-color: white;
    padding: 40px 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: -50px;
}

.sectionHeading {
    text-align: center;
    margin-bottom: 40px;
}

.titleDesign {
    width: 100px;
    height: 4px;
    background-color: #1890ff;
    margin: 0 auto;
    margin-top: 10px;
}

.aboutOuter {
    font-size: 16px;
    line-height: 1.6;
}

.description {
    text-transform: capitalize;
}
